import { Typography } from "@mui/material";;
import { Box, Button, Grid, InputLabel } from "@mui/material";
import { RedTheme } from 'common/global';
import { DateRangePicker } from "common/components";
import { CustomButton } from "./customButton";
import { CustomDropDown } from "./customDropDown";
import { useState } from "react";
import { GenericAutocomplete } from "./genericAutocomplete";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { UserInputPopup } from 'TAP/components/UserInputPopup';
import { FavDropDown } from "./FavDropDown";
import { useScreen } from "common/hooks/useScreen";
import { Divider } from "antd";

export const AdminQuickFilter = ({
    selectFav,
    disableAddToFavBtn,
    favList,
    AddFavoriteFilter,
    handleFavDelete,
    favName,
    saveFavHandler,
    getEqualityCheck,
    handleAdminStatusChange,
    legalEntityOptions,
    legalEntityValues,
    countryOptions,
    countryValues,
    partnerCompanyOptions,
    partnerCompanyValues,
    resetSearch,
    applyFilterHandler,
    mrcNameOptions,
    mrcNameValues,
    userOptions,
    userValues,
    managerOptions,
    managerValues,
    exportData,
    selectedDate,
    exportTrainingHandler,
    favUserInputValidation, 
    inputFieldHeader,
    handleClose
}) => {
    let redtheme = RedTheme;
    // Fetch MyComplianceStatus data and dashboard data from redux store
    // MyComplianceStatusvalues conditions for color
    // MyComplianceStatusvalues data

    const USER = "user";
    const MANAGER = "manager";
    const MRC = "MRC";
    const LEGALENTITY = "LegalEntity";
    const COUNTRY = "Country";
    const PARTNER_COMPANY = "PartnerCompany";
    const DATE = "Date";
    const FAVOURITE = "Favourite";

    const [userSwitch, setUserSwitch] = useState(true)
    const [managerSwitch, setManagerSwitch] = useState(true)
    const [mrcSwitch, setMRCSwitch] = useState(true)
    const [userChecked, setUserChecked] = useState(false);
    const [managerChecked, setManagerChecked] = useState(false);
    const [mrcChecked, setMrcChecked] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const screen = useScreen(); 

    const switchChange = (_e, type) => {
        if (type === USER) {
            setUserSwitch(!userSwitch)
            setUserChecked(!userChecked)
        }
        else if (type === MANAGER) {
            setManagerSwitch(!managerSwitch)
            setManagerChecked(!managerChecked)
        }
        else if (type === MRC) {
            setMRCSwitch(!mrcSwitch)
            setMrcChecked(!mrcChecked);
        }
    }

    const resetSwitch = () => {
        setUserSwitch(true);
        setManagerSwitch(true);
        setMRCSwitch(true);
        setUserChecked(false);
        setManagerChecked(false);
        setMrcChecked(false);
    }

    const getLabeledOptions = (option, type) => {
        if (type === USER) {
            return `(${option.user_id}) ${option.full_name}`
        }
        else if (type === MANAGER) {
            return `(${option.manager_id}) ${option.manager_ful_lname}`
        }
        else if (type === MRC) {
            return option.mrc !== undefined ? option.mrc : option
        }
    }

    const QuikFilterHeader = () => {
        return (
            <Typography
                        component='h4'
                        variant='h4'
                        sx={{
                            fontSize: screen.isMobile ? '20px' : '25px',
                            lineHeight: screen.isMobile ? '24px' : '32px',
                            fontWeight: 700,
                            marginTop: screen.isTablet ? '18px':'25px',
                            fontFamily: '"JohnsonDisplay-Bold"',
                        }}
                    >
                        Quick Filters
                    </Typography>
        )
    }

    const ResetButton = () => {
        return (
            <Button
                            data-testid='requestToAddOrRemoveTrainingBtn'
                            onClick={() => { resetSearch(); resetSwitch() }}
                            sx={{
                                paddingTop: '7px',
                                width: screen.isTablet ? '60px':'120px',
                                height: '32px',
                                borderRadius: '8px',
                                marginTop: '15px',
                                marginLeft: screen.resolveStyles({
                                    mobile : '0px',
                                    tablet : '10px',
                                    desktop : '20px',
                                }),
                                color: redtheme.palette.text.primary,
                                border: `1px solid ${redtheme.palette.text.primary}`,
                                bgcolor: redtheme.palette.background.default,
                                textTransform: 'none',
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: '16px',
                                fontFamily: '"JohnsonText-Medium"',
                                '&:focus': { outline: 'none' },
                            }}
                        >
                            Reset
                        </Button>
        )
    }

    const ApplyFilterButton = () => {
        return (<Button
                                data-testid='goToSummit'
                                onClick={applyFilterHandler}
                                sx={{
                                    paddingTop: '7px',
                                    width: screen.isTablet ? '110px':'120px',
                                    height: '32px',
                                    borderRadius: '8px',
                                    marginTop: '15px',
                                    marginLeft: screen.resolveStyles({
                                        mobile : '0px',
                                        tablet : '10px',
                                        desktop : '20px',
                                    }),
                                    color: redtheme.palette.background.default,
                                    bgcolor: redtheme.palette.text.primary,
                                    fontSize: '16px',
                                    lineHeight: '16px',
                                    textTransform: 'capitalize',
                                    fontWeight: 500,
                                    fontFamily: '"JohnsonText-Medium"',
                                    '&:hover': { backgroundColor: redtheme.palette.text.primary, color: redtheme.palette.background.default },
                                }}
                            >
                                Apply Filters
                            </Button>)
    }

    const ExportDataButton = () => {
        return (<CustomButton
                            // width='162px'
                            buttonText='Export Data Table'
                            clickHandler={exportData}
                        />)
    }

    const ExportTrainingTableButton = () => {
        return (<CustomButton
                            // width='175px'
                            buttonText='Export Training Table'
                            clickHandler={exportTrainingHandler}
                        />)
    }

    const FavDropdownSelect = () => {
        return (<FavDropDown
                            options={favList}
                            indicator={FAVOURITE}
                            selectFav={selectFav}
                            handleAdminStatusChange={handleAdminStatusChange}
                            handleFavDelete={handleFavDelete}
                        />)
    }

    const AddToFavouriteButton = () => {
        return (<Button
                            data-testid='requestToAddFavouriteFilter'
                            onClick={() => setOpenPopup(true)}
                            disabled={disableAddToFavBtn}
                            startIcon={<FavoriteBorderOutlinedIcon />}
                            sx={{
                                paddingTop: '7px',
                                width: '171px',
                                height: '32px',
                                borderRadius: '8px',
                                marginTop: '15px',
                                // marginLeft: '20px',
                                color: disableAddToFavBtn ? redtheme.palette.button.gray : redtheme.palette.text.primary,
                                border: disableAddToFavBtn ? '' : `1px solid ${redtheme.palette.primary.main}`,
                                backgroundColor: disableAddToFavBtn ? redtheme.palette.button.light_gray : 'transparent',
                                textTransform: 'none',
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: '16px',
                                fontFamily: '"JohnsonText-Medium"',
                                '&:focus': { outline: 'none' },
                            }}
                        >
                            Add to Favourites
                        </Button>)
    }

    const HeaderViewForNonMobile = () => {
        return (<>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '0px 0 0px',
                        background: redtheme.palette.box.white,
                        // padding: '0 16px 0 0px',
                    }}
                >
                    {QuikFilterHeader()}
                    <Box>
                    {!screen.isMobile ? <>
                        {ResetButton()}
                        <>
                            {ApplyFilterButton()}
                        </>
                        {ExportDataButton()}
                        {ExportTrainingTableButton()}
                        </> : AddToFavouriteButton()}
                    </Box>
                </Box>
                {!screen.isMobile ? (<Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '0px 0 12px',
                        background: redtheme.palette.box.white,
                        padding: '0px',
                    }}
                >
                    <Box sx={{ width: '50%' }}>
                        {FavDropdownSelect()}
                    </Box>
                    <Box>
                        {AddToFavouriteButton()}
                    </Box>
                </Box>) : (
                    <Grid container>
                    <Grid item xs={12}>
                    {FavDropdownSelect()}
                    </Grid>
                    <Grid item xs={6}>
                    {ExportDataButton()}
                    </Grid>
                    <Grid item xs={6}>
                    {ExportTrainingTableButton()}
                    </Grid>
                    </Grid>
                )}</>)
    }

    return (
        <>
            <Box sx={{
                width: '90vw',
                height: '930px'
            }}>
                {/*!screen.isMobile && */HeaderViewForNonMobile()}
                <Grid container spacing={2}>
                    <Grid item xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })}>
                        <GenericAutocomplete
                            options={userOptions}
                            values={userValues}
                            switchChangeHandler={switchChange}
                            checked={userChecked}
                            getLabeledOptions={getLabeledOptions}
                            handleAdminStatusChange={handleAdminStatusChange}
                            getEqualityCheck={getEqualityCheck}
                            type={USER}
                            placeholder={userSwitch ? userValues?.length ? `${userValues.length} Search` : `Search` : `${userValues.length} Selected`}
                            heading={!userSwitch ? 'Selected WWID / User Name' : 'WWID / User Name'}
                            switchIndicator={userSwitch}
                        />
                    </Grid>
                    <Grid item xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })}>
                        <GenericAutocomplete
                            options={managerOptions}
                            values={managerValues}
                            switchChangeHandler={switchChange}
                            checked={managerChecked}
                            getLabeledOptions={getLabeledOptions}
                            handleAdminStatusChange={handleAdminStatusChange}
                            getEqualityCheck={getEqualityCheck}
                            type={MANAGER}
                            placeholder={managerSwitch ? managerValues?.length ? `${managerValues.length} Search` : `Search` : `${managerValues.length} Selected`}
                            heading={!managerSwitch ? 'Selected Manager WWID / User Name' : 'Manager WWID / User Name'}
                            switchIndicator={managerSwitch}
                        />
                    </Grid>
                    <Grid item xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })}>
                        <GenericAutocomplete
                            options={mrcNameOptions}
                            values={mrcNameValues}
                            switchChangeHandler={switchChange}
                            checked={mrcChecked}
                            getLabeledOptions={getLabeledOptions}
                            handleAdminStatusChange={handleAdminStatusChange}
                            getEqualityCheck={getEqualityCheck}
                            type={MRC}
                            placeholder={mrcSwitch ? mrcNameValues?.length ? `${mrcNameValues.length} Search` : `Search` : `${mrcNameValues.length} Selected`}
                            heading={!mrcSwitch ? 'Selected MRC Name' : 'MRC Name'}
                            switchIndicator={mrcSwitch}
                        />
                    </Grid>
                    <Grid item xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })} style={{ position: 'relative', width: '200px' }}>
                        <CustomDropDown
                            headerText='Legal Entity Name'
                            selectedValues={legalEntityValues}
                            handleAdminStatusChange={handleAdminStatusChange}
                            indicator={LEGALENTITY}
                            options={legalEntityOptions}
                            width='100%'
                            position='absolute'
                        />
                    </Grid>
                    <Grid item xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })} style={{ position: 'relative', width: '200px' }}>
                        <CustomDropDown
                            headerText='Country'
                            selectedValues={countryValues}
                            handleAdminStatusChange={handleAdminStatusChange}
                            indicator={COUNTRY}
                            options={countryOptions}
                            width='100%'
                            position='absolute'
                        />
                    </Grid>
                    <Grid item xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })} style={{ position: 'relative', width: '200px' }}>
                        <CustomDropDown
                            headerText='Partner Company'
                            selectedValues={partnerCompanyValues}
                            handleAdminStatusChange={handleAdminStatusChange}
                            indicator={PARTNER_COMPANY}
                            options={partnerCompanyOptions}
                            width='100%'
                            position='absolute'
                        />
                    </Grid>
                    <Grid item xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })}>
                        <InputLabel
                            data-testid='date-search'
                            sx={{
                                position: 'absolute',
                                paddingTop: '15px',
                                fontSize: '12px',
                                lineHeight: '12px',
                                fontWeight: 500,
                                fontFamily: '"JohnsonText-Bold"',
                                color: 'rgba(33, 33, 33, 1)',
                            }}
                        >
                            Date Range
                        </InputLabel>
                        <DateRangePicker
                            onChange={(e) => handleAdminStatusChange(e, DATE)}
                            isAdmin={true}
                            value={selectedDate}
                        />
                    </Grid>
                    <Grid item xs={screen.resolveStyles({
              mobile: 6,
              tablet: 4,
              desktop: 3,
            })} style={{ position: 'relative', width: '200px' }}>
                        {screen.isMobile && ResetButton()}
                    </Grid>
                    <Grid item xs={screen.resolveStyles({
              mobile: 6,
              tablet: 4,
              desktop: 3,
            })} style={{ position: 'relative', width: '200px' }}>
                        {screen.isMobile && ApplyFilterButton()}
                    </Grid>
                    <Grid item xs={screen.resolveStyles({
              mobile: 12,
              tablet: 4,
              desktop: 3,
            })} style={{ position: 'relative', width: '200px' }}>
                        {screen.isMobile && <Divider
                sx={{
                  marginBottom: '25px',
                  marginTop: '10px',
                  strokeWidth: 4.5,
                  border: '3px solid #E8E6E3',
                }}
              />}
                    </Grid>
                </Grid>
            </Box>
            <UserInputPopup
                popupHeader='Add Filters to Favourite'
                inputFieldHeader= {inputFieldHeader}
                placeholder='Enter Text'
                leftBtnText='Cancel'
                rightBtnText='Save'
                CharLimit='100 Characters'
                open={openPopup}
                handleClose={() => {setOpenPopup(false); handleClose()}}
                AddFavoriteFilter={AddFavoriteFilter}
                favName={favName}
                saveFavHandler={saveFavHandler}
                favUserInputValidation = {favUserInputValidation}
            />
        </>
    )
}