import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DateRangePicker, DropDownIcon, ToolTip, ToolTipWrapper } from 'common/components';
import React, { useCallback, useEffect, useState } from 'react';
import { RedTheme, SelectedTheme } from 'common/global';
import { SearchTrainingsDataByTitle, TrainingStatusData } from 'TAP/redux/actions';
import { useDispatch, useSelector } from 'react-redux';

import { DataGrid } from '@mui/x-data-grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { TapGlobalMessages } from 'TAP/global';
import clsx from 'clsx';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { useScreen } from 'common/hooks/useScreen';
import { NUMBER_ARRAY } from 'common/global/constants';
import { tablePaginationClasses } from "@mui/material/TablePagination";

export const TrainingTable = () => {
  const theme = SelectedTheme();
  let redtheme = RedTheme;
  {
    /* Below data are kept as state 
    userId -> Id of logged in user,
    selectedTraining -> Selected training from Autocomplete suggestion  ,
    selectedStatus -> Status selected from dropdown, 
    selectedDateRange -> Selected date range using DateRangePicker,
    searchTitleOptions -> Options that need to be rendered in AutoComplete ,
    searchTermTitle -> Responsible for showing LOA dialog,
    noTrainingsAssigned -> To display content if no Trainings are assigned
    paginationModel -> 
    pageState -> 
  */
  }
  const [userId, setUserId] = useState('');
  const [selectedTraining, setSelectedTraining] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [searchTermTitle, setSearchTermTitle] = useState('');
  const [searchTitleOptions, setSearchTitleOptions] = useState([]);
  const [noTrainingsAssigned, setNoTrainingsAssigned] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    page: 0,
    pageSize: 10,
    sortField: 'training_title',
    orderBy: 'asc',
  });
  const screen = useScreen();

  {
    /* Available Page size options for Pagination */
  }
  const PAGE_SIZE_10 = 10;
  const PAGE_SIZE_20 = 20;
  const PAGE_SIZE_30 = 30;
  const PAGE_SIZE_50 = 50;
  const PAGE_SIZE_100 = 100;

  {
    /* This array will be passed to DataGrid for available page size options */
  }
  const availablePageSizeOptions = [
    PAGE_SIZE_10,
    PAGE_SIZE_20,
    PAGE_SIZE_30,
    PAGE_SIZE_50,
    PAGE_SIZE_100,
  ];

  const [sortingTooltip, setSortingTooltip] = useState('');

  const CLICK_TO_SORT_ASCENDING = 'Click to sort ascending';
  const CLICK_TO_SORT_DESCENDING = 'Click to sort descending';

  const dispatch = useDispatch();
  // From Redux store tapUserDetailsRd is getting fetched
  let getLoggedInUserDetails = useSelector(state => state.tapUserDetailsRd);

  // finds and update userId from getLoggedInUserDetails if available
  useEffect(() => {
    setSortingTooltip(CLICK_TO_SORT_ASCENDING);

    if (getLoggedInUserDetails && Object.keys(getLoggedInUserDetails).length) {
      setUserId(getLoggedInUserDetails?.userDetails?.data[0].user_id);
    }
  }, [getLoggedInUserDetails]);

  // fetches trainingMetricsRd from store to display training details
  const dashboardData = useSelector(state => state.trainingMetricsRd);

  {
    /* updates pageState and statusValue and dispatches TrainingStatusData action,
    whenever any of this data changes
    userId,
    pageState.page,
    pageState.pageSize,
    pageState.sortField,
    pageState.orderBy,
    selectedTraining,
    selectedStatus,
    selectedDateRange,

*/
  }
  useEffect(() => {
    if (userId !== undefined && userId !== '') {
      setPageState(old => ({ ...old, isLoading: true }));

      let statusValue =
        selectedStatus?.length && selectedStatus?.toUpperCase() !== 'ALL' ? selectedStatus : '';

      const payloadUser = {
        userId: userId,
        sortField: pageState.sortField,
        orderBy: pageState.orderBy,
        page: pageState.page,
        limit: pageState.pageSize,
        training_title: selectedTraining,
        t_transcript_status: statusValue,
        transcriptDate: selectedDateRange,
      };
      dispatch(TrainingStatusData(payloadUser));
    }
  }, [
    userId,
    pageState.page,
    pageState.pageSize,
    pageState.sortField,
    pageState.orderBy,
    selectedTraining,
    selectedStatus,
    selectedDateRange,
  ]);

  useEffect(() => {
    if (dashboardData?.trainingStatusDetails?.data !== undefined) {
      setPageState(old => ({
        ...old,
        isLoading: false,
        data: dashboardData?.trainingStatusDetails?.data?.records,
      }));

      dashboardData?.trainingStatusDetails?.data?.records !== undefined &&
      dashboardData?.trainingStatusDetails?.data?.records?.length > 0
        ? setNoTrainingsAssigned(false)
        : setNoTrainingsAssigned(true);
    }
    if (
      dashboardData?.searchTrainingTitlesData !== undefined &&
      Object.keys(dashboardData?.searchTrainingTitlesData).length &&
      dashboardData?.searchTrainingTitlesData?.data !== undefined &&
      dashboardData?.searchTrainingTitlesData?.data?.length
    ) {
      setSearchTitleOptions(dashboardData?.searchTrainingTitlesData?.data);
    } else {
      setSearchTitleOptions([]);
    }
  }, [dashboardData]);

  {
    /* Renders Columns of Training table  */
  }

  const columns = [
    // Renders Training Name as Header
    // it is Wrapped in ToolTip with max no character visible equal to 75
    {
      field: 'training_title',
      renderHeader: () => (
        <Box>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '16px',
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.text.secondary,
            }}
          >
            Training Name
          </Typography>
        </Box>
      ),
      flex: screen.isMobile ? 0 : 5,
      minWidth: screen.resolveStyles({ mobile: 210, tablet: 235, desktop: 0 }),
      disableColumnMenu: true,
      renderCell: params => {
        const training_title = params.value || '';
        return (
          <ToolTip title={training_title} placement='top' header='Training Name' noOfLetters={75} />
        );
      },
    },
    // Renders Curriculum Title as Header
    // it is Wrapped in ToolTip with max no character visible equal to 25
    {
      field: 'curriculum_title',
      renderHeader: () => (
        <Box>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '16px',
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.text.secondary,
            }}
          >
            Curriculum Title
          </Typography>
        </Box>
      ),
      flex: screen.isMobile ? 0 : 1.6,
      minWidth: screen.resolveStyles({ mobile: 200, tablet: 200, desktop: 0 }),
      disableColumnMenu: true,
      renderCell: params => {
        const curriculum_title = params.value || '';
        return (
          <Box
            whiteSpace='break-spaces !important'
            overflow='auto'
            sx={{ display: 'flex', height: '48px', alignItems: 'center' }}
          >
            <ToolTip
              title={curriculum_title}
              placement='top'
              header='Curriculum Title'
              noOfLetters={25}
            />
          </Box>
        );
      },
    },
    // Renders Status  as Header
    // it is Wrapped in ToolTip with max no character visible equal to 25
    {
      field: 't_transcript_status',
      renderHeader: () => (
        <Box data-testid='status_header'>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '16px',
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.text.secondary,
            }}
          >
            Status
          </Typography>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '16px',
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.fontColor.gray,
            }}
          >
            Completion Date
          </Typography>
        </Box>
      ),
      flex: screen.isMobile ? 0 : 1.1,
      minWidth: screen.resolveStyles({ mobile: 200, tablet: 200, desktop: 0 }),
      disableColumnMenu: true,
      cellClassName: params => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          completed: params.value.toLowerCase() === 'completed on time',
          overdue: ['incomplete overdue', 'complete overdue'].includes(params.value.toLowerCase()),
          noDueDate: params.value.toLowerCase() === 'no due date',
          inComplete: params.value.toLowerCase() === 'incomplete',
        });
      },
      renderCell: params => {
        return (
          <Box display='flex' flexDirection='column'>
            <span>
              {' '}
              <Typography
                sx={{
                  fontFamily: '"JohnsonText-Regular"',
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '14px',
                }}
              >
                {params.value}
              </Typography>
            </span>
            <Box sx={{ color: redtheme.palette.box.dark_grayish_orange }}>
              {formatStatus(params)}
            </Box>
          </Box>
        );
      },
    },
    {
      field: 't_transcript_due_date',
      renderHeader: () => (
        <Box>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '16px',
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.text.secondary,
            }}
          >
            Due Date
          </Typography>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '16px',
              lineHeight: '16px',
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.fontColor.gray,
            }}
          >
            Days Overdue
          </Typography>
        </Box>
      ),
      disableColumnMenu: true,
      flex: screen.isMobile ? 0 : 1.2,
      minWidth: screen.resolveStyles({ mobile: 150, tablet: 150, desktop: 0 }),
      renderCell: params => {
        return (
          <Box display='flex' flexDirection='column'>
            <span>
              {' '}
              <Typography
                sx={{
                  fontFamily: '"JohnsonText-Regular"',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '14px',
                }}
              >
                {params.value}
              </Typography>
            </span>
            <Box>{formatDueDate(params)}</Box>
          </Box>
        );
      },
    },
  ];

  const formatStatus = params => {
    if (params.value.toLowerCase() === 'completed on time') {
      return (
        <span>
          <Typography
            sx={{
              fontFamily: '"JohnsonText-Regular"',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '14px',
              color: redtheme.palette.text.secondary,
            }}
          >
            {params.row?.t_transcript_completed_date}
          </Typography>
        </span>
      );
    } else if (params.value.toLowerCase() === 'complete overdue') {
      return (
        <span>
          <Typography
            sx={{
              fontSize: '14px',
              fontFamily: '"JohnsonText-Medium"',
              fontWeight: 400,
              lineHeight: '14px',
              color: redtheme.palette.text.secondary,
            }}
          >
            {params.row?.t_transcript_completed_date}
          </Typography>
        </span>
      );
    } else {
      return (
        <span>
          <Typography sx={{ fontSize: '16px', lineHeight: '16px' }}>-</Typography>
        </span>
      );
    }
  };

  const formatDueDate = params => {
    if (
      params?.row?.t_transcript_status?.toLowerCase() === 'completed on time' ||
      params?.row?.t_transcript_status?.toLowerCase() === 'no due date'
    ) {
      return (
        <span>
          <Typography sx={{ color: redtheme.palette.fontColor.black_gray }}> - </Typography>
        </span>
      );
    } else if (params?.row?.t_transcript_status?.toLowerCase() === 'incomplete') {
      return (
        <span>
          <Typography
            sx={{
              fontFamily: '"JohnsonText-Regular"',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '14px',
            }}
          >
            {' '}
          </Typography>
        </span>
      );
    } else {
      return (
        <span>
          <Typography
            sx={{
              fontFamily: '"JohnsonText-Regular"',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '14px',
            }}
          >
            {params?.row?.t_days_overdue} Days Overdue
          </Typography>
        </span>
      );
    }
  };

  const handleSortModelChange = useCallback(sortModel => {
    setPageState(old => ({
      ...old,
      sortField: sortModel[0]?.field,
      orderBy: sortModel[0]?.sort,
    }));
    if (sortModel[0]?.sort.toUpperCase() === 'ASC') {
      setSortingTooltip(CLICK_TO_SORT_DESCENDING);
    } else if (sortModel[0]?.sort.toUpperCase() === 'DESC') {
      setSortingTooltip(CLICK_TO_SORT_ASCENDING);
    }
  }, []);

  const handleAutocompleteChange = (_event, newValue, type) => {
    if (type === 'training') {
      if (newValue?.training_title?.length) {
        setSelectedTraining(newValue?.training_title);
      } else setSelectedTraining('');
    }

    setPageState(old => ({
      ...old,
      page: 0,
    }));
    setPaginationModel(old => ({
      ...old,
      page: 0,
    }));
  };

  const onChangeOfTrainingTitleSearch = (_event, value, reason) => {
    setSearchTitleOptions([]);

    if (value?.length) {
      setSearchTermTitle(value);
    } else {
      setSearchTermTitle('');
      setSearchTitleOptions([]);
    }
    if (reason === 'clear' || reason === 'reset') {
      setSearchTermTitle('');
      setSearchTitleOptions([]);
    }
  };

  useEffect(() => {
    if (userId !== undefined && userId !== '' && searchTermTitle !== undefined) {
      let statusValue =
        selectedStatus?.length && selectedStatus?.toUpperCase() !== 'ALL' ? selectedStatus : '';

      const dataPayload = {
        userId: userId,
        training_title: searchTermTitle,
        transcriptStatus: statusValue,
        transcriptDate: selectedDateRange,
      };
      dispatch(SearchTrainingsDataByTitle(dataPayload));
    }
  }, [searchTermTitle, userId, selectedStatus, selectedDateRange]);

  const handleSelectStatusChange = event => {
    if (event.target.value?.length) {
      setSelectedStatus(event.target.value);
    } else {
      setSelectedStatus('');
    }

    setPageState(old => ({
      ...old,
      page: 0,
    }));
    setPaginationModel(old => ({
      ...old,
      page: 0,
    }));
  };

  const onChangeOfDateRange = date => {
    if (date?.length && date[0]?.length) {
      setSelectedDateRange(date);
    } else {
      setSelectedDateRange([]);
    }

    setPageState(old => ({
      ...old,
      page: 0,
    }));
    setPaginationModel(old => ({
      ...old,
      page: 0,
    }));
  };

  const customNoDataOverlay = () => {
    let statusValue =
      selectedStatus?.length && selectedStatus?.toUpperCase() !== 'ALL' ? selectedStatus : '';

    let overlayMssg =
      selectedTraining === '' && statusValue === '' && selectedDateRange.length === 0
        ? 'No Trainings assigned to you at the moment'
        : 'No Trainings found for the filters applied';

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px',
          background: redtheme.palette.box.very_light_grey,
          fontWeight: '400',
          fontSize: '32px',
          lineHeight: '40px',
          fontFamily: 'JohnsonDisplay-Regular',
          color: redtheme.palette.fontColor.dark_grayish_blue,
          width: '100%',
          height: '100%',
        }}
      >
        {overlayMssg}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box
        display='flex'
        flexDirection='row'
        sx={screen.isMobile || screen.isTablet ? { flexWrap: 'wrap', alignItems: 'center' } : {}}
        mb={2}
      >
        <Box
          sx={{
            width: screen.resolveStyles({ mobile: '50%', tablet: `${100 / 3}%`, desktop: '58%' }),
          }}
        >
          <Autocomplete
            id='search-training-by-title'
            data-testid='search-training-by-title'
            options={searchTitleOptions}
            popupIcon={''}
            getOptionLabel={option => option.training_title}
            onInputChange={onChangeOfTrainingTitleSearch}
            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'training')}
            renderInput={params => (
              <TextField
                data-testid='trainingNameInput'
                {...params}
                InputProps={{
                  ...params.InputProps,
                  shrink: true,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder='Search'
                label={
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '14px',
                      fontWeight: 500,
                      fontFamily: '"JohnsonText-Bold"',
                      color: 'rgba(33, 33, 33, 1)',
                      width: '728px',
                      height: '16px',
                    }}
                  >
                    Training Name
                  </Typography>
                }
                sx={{
                  borderBottom: `1px solid ${redtheme.palette.fontColor.gray_orange}`,
                  marginBottom: screen.isTablet && NUMBER_ARRAY.zero,
                }}
                variant='standard'
                margin='normal'
              />
            )}
            sx={{ mr: 2 }}
            clearOnBlur
          />
        </Box>

        <Box
          sx={{
            width: screen.resolveStyles({ mobile: '50%', tablet: `${100 / 3}%`, desktop: '20%' }),
            fontFamily: '"JohnsonText-Medium"',
          }}
        >
          <FormControl
            variant='standard'
            data-testid='status_filter'
            sx={{
              width: '95%',
              mt: 2,
              fontFamily: '"JohnsonText-Medium"',
              ...(screen.isMobile ? { marginTop: '8px' } : {}),
            }}
          >
            <InputLabel
              variant='standard'
              id='select-status-label'
              sx={{
                fontSize: '16px',
                lineHeight: '14px',
                fontWeight: 500,
                fontFamily: '"JohnsonText-Bold"',
                color: 'rgba(33, 33, 33, 1)',
                width: '728px',
                height: '16px',
              }}
              shrink={true}
            >
              Status
            </InputLabel>
            <Select
              id='dd-select-status'
              data-testid='statusInput'
              IconComponent={DropDownIcon}
              value={selectedStatus}
              onChange={handleSelectStatusChange}
              sx={{ borderBottom: `1px solid ${redtheme.palette.fontColor.gray_orange}` }}
              displayEmpty={true}
              renderValue={selected => {
                if (selected.length === 0) {
                  return (
                    <Typography
                      data-testid='statusInputSelect'
                      sx={{
                        color: redtheme.palette.fontColor.gray_orange,
                        fontSize: '16px',
                        fontWeight: 400,
                      }}
                    >
                      Select
                    </Typography>
                  );
                }
                return selectedStatus;
              }}
            >
              <MenuItem value='All'>All</MenuItem>
              {TapGlobalMessages.myPerformance.statusOptionsList.map(status => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            width: screen.resolveStyles({ mobile: '70%', tablet: `${100 / 3}%`, desktop: '20%' }),
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box sx={{ display: 'flex' }} data-testid='dateRange_filter'>
            <InputLabel
              sx={{
                position: 'absolute',
                paddingTop: '15px',
                fontSize: '12px',
                lineHeight: '12px',
                fontWeight: 500,
                fontFamily: '"JohnsonText-Bold"',
                color: 'rgba(33, 33, 33, 1)',
              }}
            >
              Start Date to End Date
            </InputLabel>
            <DateRangePicker onChange={onChangeOfDateRange} />
            <Box
              sx={{
                display: 'flex',
                width: '20%',
                alignItems: 'end',
              }}
            >
              <ToolTipWrapper
                title={TapGlobalMessages.myPerformance.myTrainingStatusDateRange()}
                placement='top'
                header=''
              >
                <HelpOutlineIcon
                  fontSize='medium'
                  sx={{
                    color: `${theme.palette.common.primaryMainBlue}`,
                    height: '15px',
                    width: '15px',
                    marginBottom: '18px',
                    marginLeft: '15px',
                  }}
                ></HelpOutlineIcon>
              </ToolTipWrapper>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: '100%', marginBottom: '20px', minHeight: 100, maxHeight: 630 }}>
        {noTrainingsAssigned ? (
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'training_title', sort: 'asc' }],
              },
            }}
            loading={pageState.isLoading}
            rows={pageState.data}
            columns={columns}
            components={{
              noRowsOverlay: customNoDataOverlay,
            }}
            sx={{
              border: 'none',
              minHeight: '480px',
              '& .MuiDataGrid-columnHeaders': {
                fontSize: 16,
                fontFamily: '"J&J CircularTT Black Web"',
              },
            }}
          />
        ) : (
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'training_title', sort: 'asc' }],
              },
            }}
            rows={pageState.data}
            columns={columns}
            getRowId={row => row?.transcript_id}
            loading={pageState.isLoading}
            components={{
              noRowsOverlay: customNoDataOverlay,
            }}
            paginationMode='server'
            sortingMode='server'
            disableRowSelectionOnClick
            localeText={{ columnHeaderSortIconLabel: sortingTooltip }}
            onSortModelChange={handleSortModelChange}
            rowCount={
              pageState?.data?.length
                ? Math.max(pageState?.data?.length, pageState.data[0]?.total_records)
                : 0
            }
            paginationModel={paginationModel}
            pageSizeOptions={availablePageSizeOptions}
            onPaginationModelChange={newPage => {
              setPageState(old => ({
                ...old,
                page: newPage.page + 1,
                pageSize: newPage.pageSize,
              }));
              setPaginationModel(old => ({
                ...old,
                page: newPage.page,
                pageSize: newPage.pageSize,
              }));
            }}
            onPageSizeChange={newPageSize =>
              setPageState(old => ({ ...old, pageSize: newPageSize }))
            }
            sx={{
              border: 'none',
              '& .MuiDataGrid-cell': {
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '14px',
                fontFamily: '"JohnsonText-Medium"',
              },
              '& .MuiDataGrid-row:nth-of-type(odd)': { backgroundColor: '#00009908' },
              '& .MuiDataGrid-columnHeaders': {
                fontSize: 16,
                fontWeight: 700,
                fontFamily: '"JohnsonText-Bold"',
              },
              '& .super-app.completed': {
                color: redtheme.palette.dataGrid.dark_lime_green,
              },
              '& .super-app.overdue': {
                color: redtheme.palette.dataGrid.strong_red,
              },
              '& .super-app.noDueDate': {
                color: redtheme.palette.dataGrid.strong_blue,
              },
              '& .super-app.inComplete': {
                color: redtheme.palette.dataGrid.strong_orange,
              },
              '& .MuiTablePagination-selectLabel': {
                fontFamily: '"JohnsonText-Regular"',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '16px',
                fontStyle: 'normal',
                color: redtheme.palette.fontColor.very_dark_grey,
              },
              '& .MuiTablePagination-displayedRows': {
                fontFamily: '"JohnsonText-Medium"',
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '16px',
                fontStyle: 'normal',
                color: redtheme.palette.fontColor.very_dark_grey,
              },
              '& .MuiTablePagination-select': {
                fontFamily: '"JohnsonText-Medium"',
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '16px',
                fontStyle: 'normal',
                color: redtheme.palette.fontColor.very_dark_grey,
              },
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
              [`& .${tablePaginationClasses.selectLabel}`]: {
                display: "block"
              },
              [`& .${tablePaginationClasses.input}`]: {
                display: "inline-flex"
              }
            }}
          />
        )}
        {/* )} */}
      </Box>
    </Box>
  );
};
