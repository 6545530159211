import { pagination } from "TAP/styles/genericStyles/pagination";

export const AUTHENTICATION_TIMEOUT = 500; 
export const LOGIN_TIMEOUT = 1500; 
export const DEFAULT_PAGE_SIZE = 10; 
export const PAGE_SIZE = 5; 
export const EST_ONE_AM = 1; 

/****
* MY ORGANISATION CONST
****/
export const myOrgPerform = {
    one : 1,
    two : 2, 
    three : 3, 
    seven : 7, 
    twelve : 12
}
/****
* GENERIC POP CONST
****/
export const genericPop = {
    twoHundred : 200, 
    five : 5, 
    ten : 10, 
    fifteen : 15, 
    nineHundred : 900, 
    sevenHundred : 700
}
/****
* BAR CHART CONST
****/
export const barchart = {
    twoHundred : 200, 
    hundred : 100, 
    two : 2, 
    three : 3, 
    five : 5, 
    ninetyfive : 95, 
    eightyfive : 85, 
    four : 4, 
    fifteen : 15, 
    threeHundred : 300, 
    fiveHundred : 500, 
    twentyeight : 28
}
/****
* DONUT CHART CONST
****/
export const donutchart = {
    twoHundred : 200,
    twoTwenty : 220, 
    hundred : 100, 
    twopointone : 2.1,
    two : 2, 
    three : 3, 
    five : 5, 
    ninetyfive : 95, 
    eightyfive : 85, 
    four : 4, 
    fifteen : 15, 
    threeHundred : 300, 
    fiveHundred : 500, 
    twentyeight : 28, 
    twofifty : 250, 
    minustwo : -2, 
    minusonethirty : -130, 
    onethirty : 130, 
    minussixty : -60, 
    twoeighty : 280
}
/****
* CIRCLE TEXT CONST
****/
export const circlewithtext = {
    twoHundred : 200, 
    eightHundred : 800
}
/****
* RENEWAL TIME CONST
****/
export const renewalTime = {
    five : 5, 
    sixty : 60,
    thousand: 1000
}


/****
* number array constant
****/
export const NUMBER_ARRAY = {
  zero: 0,
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  nine: 9,
  ten: 10,
  twelve: 12,
  twenty: 20,
  fifty: 50,
  seventy: 70,
  hundred: 100,
  eight_hundred: 800,
  thousand: 1000,
  twoPointEight: 2.8,
  twoPointFive: 2.5,
  twoPointTwo: 2.2,
  minusTwo: -2,
  threePointFive: 3.5,
  twoFifty: 250,
  zeroPointEightySeven: 0.87,
  twoHundred: 200,
  fourHundred: 400,
  fourHundredOne: 401,
  fourHundredThree: 403,
  fourHundredFour: 404,
  fourHundredTwenty: 420,
  fourHundredThirty: 430,
  fiveHundred: 500,
  fiveHundredTwo: 502,
  fiveHundredOne: 501,
  sevenHundred: 700,
  sevenHundredSixtyEight: 768,
  twoThousand: 2000,
  zeroPointTwo: 0.2,
};

export const labels = {
    errorHeader: 'Unexpected Error',
    errorSubHeader: 'Sorry for the inconvenience', 
    errorBody: "Please try refreshing this page. If the problem persists, please close your browser and re-load, or you can logout the application also.",
    errorButtonText: 'Logout',
    unauthorizedHeader: 'No Access!',
    unauthorizedBody: "You don't have access to TAP",
    unauthorizedButtonText: 'Go To DTH',
}


export const TOKEN_RENEWAL_TIME = renewalTime.five * renewalTime.sixty * renewalTime.thousand; 

export const PAGE_SIZES_ARRAY_GENERIC = [pagination.pg5,pagination.pg10,pagination.pg15]

export const PAGE_SIZES_ARRAY = [pagination.pg10,pagination.pg20,pagination.pg30,pagination.pg50,pagination.pg100]