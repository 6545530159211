import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  Stack,
  Autocomplete,
  TextField,
  Snackbar,
  InputLabel,
  Grid,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { UseDebounce } from 'common/hooks';
import { DateRangePicker } from 'common/components';
import {
  PopupDetailsData,
  PopupUpFilterData,
  AdminPopupDetailsData,
  PopupAdminFilterData,
  DirectReporteePopupDetailsData,
  DirectReporteePopupFilterData,
  DirectReporteeTrainingsPopupFilterData,
  PopupUpReminder,
} from 'TAP/redux/actions';
import MuiAlert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import dayjs from 'dayjs';
import _ from 'lodash';
import { RedTheme } from 'common/global';
import { genericPop, PAGE_SIZES_ARRAY_GENERIC, NUMBER_ARRAY } from 'common/global/constants';
import { fontWeightStyles } from 'TAP/styles/genericStyles/fontWeights';
import PropTypes from 'prop-types';
import { ReactComponent as PopupInfo } from 'common/assets/images/genricPopUpInfo.svg';
import { GenericPopupTooltip } from 'common/components/genericPopupTooltip'; 
import { downloadExcelReport, getGAMeasurementId } from 'TAP/utils/commonMethods';
import ReactGA from 'react-ga4'; 
import { useScreen } from 'common/hooks/useScreen';
import { ReactComponent as Download } from 'common/assets/images/Down_load.svg';
import { tablePaginationClasses } from "@mui/material/TablePagination";


let redtheme = RedTheme;
let arrayValue = [];
let uniqueArray = [];
let arrcheckbox = [];
let checkboxArray = [];
let totalSelectedRecords;
let AllRowIDSData = [];
let selectAllCheckboxValue, deSelectedIds;
let unselectedRows = [];

function GenericPopup({
  open,
  onClose,
  selectedDays,
  columns,
  buttons,
  autocompletes,
  statusMapper,
  popupHeader,
  popUserId,
  popupStatus,
  dateRangeParent,
  setAutocompleteConfigs = null,
  isCheckboxSelection = false,
  isAdmin = false,
  buttonDisabled = true,
  selectedTabValue = 0,
  isCompliantPopup = false,
  partnerCompanyValues,
  legalEntityValues,
  countryValues,
  mrcNameValues,
  userValues,
  managerValues,
}) {
  const [searchText, setSearchText] = useState('');
  const filterText = UseDebounce(searchText, genericPop.twoHundred);
  const [searchTeamMember, setSearchTeamMember] = useState('');
  const teamMemberSearch = UseDebounce(searchTeamMember, genericPop.twoHundred);
  const [selectedOption, setSelectedOption] = useState('');
  const [trainingTitle, setTrainingTitle] = useState('');
  const [trainingStatus, setTrainingStatus] = useState('');

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [noTrainingsAssigned, setNoTrainingsAssigned] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [rowData, setRowData] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [dateRangeFilter, setDateRangeFilter] = useState(dateRangeParent);
  const [logInUserDetails, setLogInUserDetails] = useState('');
  const [initializer, setInitializer] = useState(false);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    page: 1,
    pageSize: 5,
  });
  const [selectedUsersForDownloadExcel, setSelectedUsersForDownloadExcel] = useState([]);
  const [deSelectedUsersForDownloadExcel, setDeSelectedUsersForDownloadExcel] = useState([]);
  const [sortingTooltip, setSortingTooltip] = useState('');
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const CLICK_TO_SORT_ASCENDING = 'Click to sort ascending';
  const CLICK_TO_SORT_DESCENDING = 'Click to sort descending';
  const screen = useScreen(); 

  const isPopupOpenedForCompliance = () => {
    return popupHeader === 'Total Non-Compliant' || popupHeader === 'Total At Risk';
  };

  useEffect(() => {
    ReactGA.initialize(`G-${getGAMeasurementId()}`);
  }, []);

  useEffect(() => {
    setSortingTooltip(CLICK_TO_SORT_ASCENDING);

    setSelectedUsersForDownloadExcel([]);
  }, [open]);

  useEffect(() => {
    if (!initializer) {
      const userDetails = localStorage.getItem('userDetails');
      if (userDetails && Object.keys(userDetails).length) {
        setLogInUserDetails(JSON.parse(userDetails));
      }

      setInitializer(true);
    }
  }, [initializer]);

  const handleClose = () => {
    setSearchText('');
    setSelectedOption('');
    setSearchTeamMember('');
    setTrainingTitle('');
    setSelectedRows([]);
    setSelectionModel([]);
    setDateRangeFilter(dateRangeParent);
    setTrainingStatus('');
    setSelectedUsersForDownloadExcel([]);
    setSelectAllChecked(false);
    selectAllCheckboxValue = false;
    arrayValue = [];
    uniqueArray = [];
    arrcheckbox = [];
    checkboxArray = [];
    unselectedRows = [];
    setDeSelectedUsersForDownloadExcel([]);
    setPageState({
      isLoading: false,
      data: [],
      page: 1,
      pageSize: 5,
      trainingTitle: '',
      userName: '',
    });

    setAutocompleteConfigs((previous) => {
      return previous.map((item) => {
        if (
          item.label === 'Team Member' ||
          item.label === 'WWID / Team Member' ||
          item.label === 'Training Name' ||
          item.label === 'Training Status'
        ) {
          item.options = [];
        }
        return item;
      });
    });

    onClose && onClose();
  };

  const dispatch = useDispatch();

  let dashboardData;
  if (isAdmin) {
    dashboardData = useSelector((state) => state.tapAdminPopUpDetailsRd);
  } else if (selectedTabValue === 1) {
    dashboardData = useSelector((state) => state.tapPopUpDetailsRd);
  } else {
    dashboardData = useSelector((state) => state.tapDirectReporteePopUpDetailsRd);
  }

  let dashboardFilter;
  if (isAdmin) {
    dashboardFilter = useSelector((state) => state.adminPopUpFilterRd);
  } else if (selectedTabValue === 1) {
    dashboardFilter = useSelector((state) => state.tappopUpFilterRd);
  } else if (
    statusMapper[popupHeader] !== undefined &&
    statusMapper[popupHeader].split('/')[1] === 'actions'
  ) {
    dashboardFilter = useSelector((state) => state.directReporteeActionpopUpFilterRd);
  } else {
    dashboardFilter = useSelector((state) => state.directReporteeTrainingpopUpFilterRd);
  }

  let DOWNLOAD_EXCEL_BUTTON_BG_COLOR =
    pageState.data?.length <= 0
      ? redtheme.palette.button.light_gray
      : redtheme.palette.primary.main;
  let DOWNLOAD_EXCEL_BUTTON_COLOR =
    pageState.data?.length <= 0 ? redtheme.palette.button.gray : redtheme.palette.button.white;
  let DOWNLOAD_EXCEL_BUTTON_FONTWEIGHT =
    pageState.data?.length <= 0 ? fontWeightStyles.fw9.fontWeight : fontWeightStyles.fw7.fontWeight;

  const ref = useRef();
  ref.current = false;

  useEffect(() => {
    setDateRangeFilter(selectedDateRange);
  }, [selectedDateRange]);

  useEffect(() => {
    if (!ref?.current) {
      setDateRangeFilter(dateRangeParent);
      ref.current = true;
    }
  }, [dateRangeParent]);

  useEffect(() => {
    if (
      statusMapper[popupHeader] !== undefined &&
      statusMapper[popupHeader] !== '' &&
      popUserId &&
      logInUserDetails.user_id !== ''
    ) {
      setPageState((old) => ({ ...old, isLoading: true }));
      const payloadUser = {
        userId: popUserId,
        sortField: pageState.sortField,
        orderBy: pageState.orderBy,
        page: pageState.page,
        limit: pageState.pageSize,
        days: selectedDays,
        userName: searchTeamMember,
        trainingTitle: trainingTitle || '',
        trainingStatus: trainingStatus,
        popUpType: popupStatus,
        urlStatus: statusMapper[popupHeader],
        dateRange: dateRangeFilter,
        loggedInUserId: logInUserDetails.user_id,
      };
      const adminPayloadUser = {
        userId: popUserId,
        sortField: pageState.sortField,
        orderBy: pageState.orderBy,
        page: pageState.page,
        limit: pageState.pageSize,
        days: selectedDays,
        userName: searchTeamMember,
        trainingTitle: trainingTitle || '',
        trainingStatus: trainingStatus,
        popUpType: popupStatus,
        urlStatus: statusMapper[popupHeader],
        dateRange: dateRangeFilter,
        loggedInUserId: logInUserDetails.user_id,
        country: countryValues?.length ? countryValues : '',
        mrc: mrcNameValues?.length ? mrcNameValues : '',
        legalEntityName: legalEntityValues?.length ? legalEntityValues : '',
        partnerCompany: partnerCompanyValues?.length ? partnerCompanyValues : '',
        userField: userValues?.length ? userValues : '',
        managerField: managerValues?.length ? managerValues : '',
      };
      dispatch(
        isAdmin
          ? AdminPopupDetailsData(adminPayloadUser)
          : selectedTabValue === 1
          ? PopupDetailsData(payloadUser)
          : DirectReporteePopupDetailsData(payloadUser),
      );
    }
  }, [
    pageState.page,
    pageState.pageSize,
    pageState.sortField,
    pageState.orderBy,
    pageState.days,
    popUserId,
    statusMapper[popupHeader],
    popupStatus,
    selectedOption,
    trainingTitle,
    dateRangeFilter,
    logInUserDetails.user_id,
  ]);

  useEffect(() => {
    setPageState({ ...pageState, isLoading: false });
    if (dashboardData?.popupDetailsData?.data?.records !== undefined) {
      setRowData([...rowData, ...dashboardData.popupDetailsData.data.records]);
      setPageState((old) => ({
        ...old,
        data: dashboardData?.popupDetailsData?.data?.records,
        total: dashboardData?.popupDetailsData?.data?.totalRecords,
      }));
      totalSelectedRecords = dashboardData?.popupDetailsData?.data?.totalRecords;

      if (selectAllChecked) {
        if (popupHeader === 'Total Non-Compliant' || popupHeader === 'Total At Risk') {
          AllRowIDSData = dashboardData?.popupDetailsData?.data?.records.map((item) => {
            return item['user_id'];
          });
          deSelectedIds = unselectedRows?.map((item) => item.user_id);
        } else {
          AllRowIDSData = dashboardData?.popupDetailsData?.data?.records.map((item) => {
            return item['transcript_id'];
          });
          deSelectedIds = unselectedRows?.map((item) => item.transcript_id);
        }

        if (unselectedRows?.length) {
          const selectedIds = AllRowIDSData.filter((item) => !deSelectedIds.includes(item));
          setSelectionModel(selectedIds);
        } else {
          setSelectionModel(AllRowIDSData);
        }

        setSelectedUsersForDownloadExcel([]);
      }

      dashboardData?.popupDetailsData?.data?.records !== undefined &&
      dashboardData?.popupDetailsData?.data?.records.length > 0
        ? setNoTrainingsAssigned(false)
        : setNoTrainingsAssigned(true);
    } else if (dashboardData?.popupDetailsData?.data?.length === 0) {
      setPageState((old) => ({
        ...old,
        data: [],
        total: 0,
      }));
    } else {
      //No valid request
    }
  }, [dashboardData]);

  const handleSnackbarOpen = (message) => {
    setSnackbarOpen(true);
    setSnackbarMessage(message);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  //false : to enable   &  true :  to disable .(Send Reminder button)
  const CheckSendReminder = () => {
    let filteredselectedRows = selectedRows.filter((item) => item.complianceElement !== '');
    return !(filteredselectedRows?.length > 0);
  };

  // popUp Reminder
  const handleSendReminder = () => {
    // Capture the event of sending mail for Google Analytics
    captureMailTriggerEvent();

    let arrSelectedRows = _.clone(selectedRows, true);
    arrSelectedRows = arrSelectedRows.filter((item) => item.complianceElement !== '');
    if (isCompliantPopup) {
      selectedRows.forEach((row) => {
        if (selectionModel.indexOf(row.user_id) < 0) {
          let index = arrSelectedRows.indexOf(
            arrSelectedRows.find((item) => item.user_id === row.user_id),
          );
          arrSelectedRows.splice(index, 1);
        }
      });
    } else {
      selectedRows.forEach((row) => {
        if (selectionModel.indexOf(row.transcript_id) < 0) {
          let index = arrSelectedRows.indexOf(
            arrSelectedRows.find((item) => item.transcript_id === row.transcript_id),
          );
          arrSelectedRows.splice(index, 1);
        }
      });
    }
    const arr = statusMapper[popupHeader].split('/');
    const payloadreminderUser = {
      userId: popUserId,
      reminderOf: arr[arr.length - 1],
      username: logInUserDetails?.full_name,
      records: arrSelectedRows,
    };
    const selectAllPayloadRemainderUser = {
      userId: popUserId,
      reminderOf: arr[arr.length - 1],
      username: logInUserDetails?.full_name,
      sendToAll: 'Y',
      screen: isAdmin ? 'adminView' : selectedTabValue === 1 ? 'myOrganisation' : 'myDirectReports',
      records: [],
      exclUserArray:
        selectAllChecked && deSelectedUsersForDownloadExcel?.length
          ? deSelectedUsersForDownloadExcel
          : '',
      page: pageState.page,
      limit: pageState.pageSize,
      days: selectedDays,
      userName: searchTeamMember,
      trainingTitle: trainingTitle || '',
      trainingStatus: trainingStatus,
      popUpType: popupStatus,
      urlStatus: statusMapper[popupHeader],
      dateRange: dateRangeFilter,
      loggedInUserId: logInUserDetails.user_id,
      country: countryValues?.length ? countryValues : '',
      mrc: mrcNameValues?.length ? mrcNameValues : '',
      legalEntityName: legalEntityValues?.length ? legalEntityValues : '',
      partnerCompany: partnerCompanyValues?.length ? partnerCompanyValues : '',
      userField: userValues?.length ? userValues : '',
      managerField: managerValues?.length ? managerValues : '',
    };

    if (selectAllChecked ) {
      dispatch(PopupUpReminder(selectAllPayloadRemainderUser));
    } else {
      dispatch(PopupUpReminder(payloadreminderUser));
    }

    handleSnackbarOpen('Reminder sent successfully!');
    setSnackbarOpen(true);
    setSelectedRows([]);
    setSelectionModel([]);
    setRowData([]);
    setSelectAllChecked(false);
    selectAllCheckboxValue = false;
    
  };
  const captureMailTriggerEvent = () => {
    let categoryName;
    if (
      statusMapper[popupHeader] !== undefined &&
      statusMapper[popupHeader].split('/')[0] === 'adminView'
    ) {
      categoryName = 'Administrative View';
    } else {
      categoryName = "My Team's Performance";
    }

    ReactGA.event({
      category: categoryName,
      action: 'Email Triggered',
    });
  };

  // Download Excel sheet
  const handleDownloadExcelUrlAddition = (urlStatus, days, first_date, second_date) => {
    let requestURL = '';
    if (urlStatus === 'adminView/trainings/upcomingDue') {
      requestURL += `?days=${days}`;
    }
    if (urlStatus === 'trainings/upcomingDue') {
      requestURL += `&days=${days}`;
    }
    if (urlStatus === 'directReportTrainings/upcomingDue') {
      requestURL += `&days=${days}`;
    }

    if (searchTeamMember?.length) {
      
      if (urlStatus === 'actions/nonCompliant' || urlStatus === 'directReport/actions/nonCompliant'){
        requestURL += `?userName=${encodeURIComponent(searchTeamMember)}`
      }else{
        requestURL += `&userName=${encodeURIComponent(searchTeamMember)}`;
      }
    }

    if (trainingTitle?.length) {
      requestURL += `&trainingTitle=${encodeURIComponent(trainingTitle)}`;
    }

    if (dateRangeFilter?.length) {
      requestURL += `&startDate=${first_date}&endDate=${second_date}`;
    }

    if (trainingStatus?.length) {
      requestURL += `&trainingStatus=${trainingStatus}`;
    }
    return requestURL;
  };
  const handleDownloadExcel = () => {
    const currentDate = new Date().toISOString().split('T')[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, '');
    const urlStatus = statusMapper[popupHeader];
    const days = selectedDays;
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const first_date = dateRangeFilter?.[0];
    const second_date = dateRangeFilter?.[1];

    let fileName = '';
    switch (urlStatus) {
      case 'trainings/totalOverDue':
        fileName = `TotalOverDue_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'adminView/trainings/totalOverDue':
        fileName = `TotalOverDue_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'trainings/upcomingDue':
        fileName = `UpcomingDue_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'adminView/trainings/upcomingDue':
        fileName = `UpcomingDue_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'trainings/incompleteOverDue':
        fileName = `IncompleteOverDue_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'adminView/trainings/incompleteOverDue':
        fileName = `IncompleteOverDue_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'trainings/incomplete':
        fileName = `Incomplete_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'adminView/trainings/incomplete':
        fileName = `Incomplete_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'trainings/completeOnTime':
        fileName = `CompleteOnTime_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'trainings/completedOverall':
        fileName = `CompleteOverall_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'directReportTrainings/completeOnTime':
        fileName = `CompleteOnTime_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'directReportTrainings/completedOverall':
        fileName = `CompleteOverall_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'adminView/trainings/completeOnTime':
        fileName = `CompleteOnTime_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'trainings/completeOverDue':
        fileName = `CompleteOverDue_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'adminView/trainings/completeOverDue':
        fileName = `CompleteOverDue_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'actions/nonCompliant':
        fileName = `NonCompliant_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'adminView/actions/nonCompliant':
        fileName = `NonCompliant_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'directReport/actions/nonCompliant':
        fileName = `NonCompliant_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'actions/atRisk':
        fileName = `AtRisk_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'adminView/actions/atRisk':
        fileName = `AtRisk_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'directReport/actions/atRisk':
        fileName = `AtRisk_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'directReportTrainings/totalOverDue':
        fileName = `TotalOverDue_${currentDate}_${currentTime}.xlsx`;
        break;
      case 'directReportTrainings/upcomingDue':
        fileName = `UpCommingDue_${currentDate}_${currentTime}.xlsx`;
        break;
      default:
        // No valid request
        break;
    }

    if (popUserId !== undefined && popupStatus !== undefined) {
      let requestURL = '';

      if (urlStatus === 'actions/nonCompliant' || urlStatus === 'actions/atRisk') {
        requestURL = `${baseURL}${urlStatus}/report/${logInUserDetails.user_id}`;
      } else if (
        urlStatus === 'directReport/actions/nonCompliant' ||
        urlStatus === 'directReport/actions/atRisk'
      ) {
        requestURL = `${baseURL}${urlStatus}/report/${logInUserDetails.user_id}`;
      } else if (urlStatus === 'adminView/trainings/totalOverDue') {
        requestURL = `${baseURL}${urlStatus}/report/?popUpType=${popupStatus}`;
      } else if (urlStatus === 'adminView/trainings/upcomingDue') {
        requestURL = `${baseURL}${urlStatus}/report`;
      } else if (
        urlStatus === 'directReportTrainings/totalOverDue' ||
        urlStatus === 'directReportTrainings/upcomingDue'
      ) {
        requestURL = `${baseURL}directReportTrainingsdownload/${urlStatus.split('/')[1]}/report/${
          logInUserDetails.user_id
        }/${popUserId}?popUpType=${popupStatus}`;
      } else if (
        urlStatus === 'directReportTrainings/completeOnTime' ||
        urlStatus === 'directReportTrainings/completedOverall'
      ) {
        requestURL = `${baseURL}directReportTrainingsdownload/${urlStatus.split('/')[1]}/report/${
          logInUserDetails.user_id
        }/${popUserId}?popUpType=${popupStatus}`;
      } else {
        requestURL = !isAdmin
          ? `${baseURL}${urlStatus}/report/${logInUserDetails.user_id}/${popUserId}?popUpType=${popupStatus}`
          : `${baseURL}${urlStatus}/report/${logInUserDetails.user_id}?popUpType=${popupStatus}`;
      }

      const queryRequestURL = handleDownloadExcelUrlAddition(
        urlStatus,
        days,
        first_date,
        second_date,
      );
      requestURL += queryRequestURL;
     
      const params = isAdmin
        ? {
            country: countryValues?.length ? JSON.stringify(countryValues) : '',
            mrc: mrcNameValues?.length ? JSON.stringify(mrcNameValues) : '',
            legalEntityName: legalEntityValues?.length ? JSON.stringify(legalEntityValues) : '',
            partnerCompany: partnerCompanyValues?.length
              ? JSON.stringify(partnerCompanyValues)
              : '',
            userField: userValues?.length ? JSON.stringify(userValues) : '',
            managerField: managerValues?.length ? JSON.stringify(managerValues) : '',
            exclUserArray:
              selectAllChecked && deSelectedUsersForDownloadExcel?.length
                ? JSON.stringify(deSelectedUsersForDownloadExcel)
                : '',
            userArray: selectedUsersForDownloadExcel?.length
              ? JSON.stringify(selectedUsersForDownloadExcel)
              : '',
          }
        : {
            userArray: selectedUsersForDownloadExcel?.length
              ? JSON.stringify(selectedUsersForDownloadExcel)
              : '',
            exclUserArray:
              selectAllChecked && deSelectedUsersForDownloadExcel?.length
                ? JSON.stringify(deSelectedUsersForDownloadExcel)
                : '',
          };
      
      downloadExcelReport(requestURL, fileName, params);
    }
  };

  // Data Table Sorting
  const handleSortModelChange = useCallback((sortModel) => {
    setPageState((old) => ({
      ...old,
      sortField: sortModel[0]?.field,
      orderBy: sortModel[0]?.sort,
    }));

    if (sortModel[0]?.sort.toUpperCase() === 'ASC') {
      setSortingTooltip(CLICK_TO_SORT_DESCENDING);
    } else if (sortModel[0]?.sort.toUpperCase() === 'DESC') {
      setSortingTooltip(CLICK_TO_SORT_ASCENDING);
    }
  }, []);

  const searchTypeRef = useRef('');
  const trainingStatusSearch = (value) => {
    searchTypeRef.current = 'trainingStatus';
    setTrainingStatus(value);
    setAutocompleteConfigs((previous) => {
      return previous.map((item) => {
        if (item.label === 'Search Training Status') {
          item.options = [];
        }
        return item;
      });
    });
  };
  const trainingSearchWithValue = (value) => {
    if (searchTypeRef.current === 'user') {
      setSearchTeamMember(value);
    } else {
      setSearchText(value);
    }
  };
  const trainingSearchWithoutValue = () => {
    if (searchTypeRef.current === 'user') {
      setSearchTeamMember('');
      setSelectedOption(filterText);
      setAutocompleteConfigs((previous) => {
        return previous.map((item) => {
          if (item.label === 'Team Member' || item.label == 'WWID / Team Member') {
            item.options = [];
          }
          return item;
        });
      });
    } else {
      setSearchText('');
      setSelectedOption(teamMemberSearch);
      setAutocompleteConfigs((previous) => {
        return previous.map((item) => {
          if (item.label === 'Training Name') {
            item.options = [];
          }
          return item;
        });
      });
    }
    setPageState((old) => ({ ...old, searchText: '' }));
  };
  const handleSearchText = (_e, value, label) => {
    if (label === 'Training Name' || label === 'Search Training Name') {
      searchTypeRef.current = 'training';
    } else {
      searchTypeRef.current = 'user';
    }
    if (label === 'Training Status') {
      trainingStatusSearch(value);
    } else if (value && value.trim() !== '') {
      trainingSearchWithValue(value);
    } else {
      trainingSearchWithoutValue();
    }
  };

  // Filter
  /**
   *
   *getDataOfValue is a function which determines the getDataof parameter in Payload
   */
  const getDataOfValue = (condition) => {
    if (condition) {
      return ' ';
    } else {
      if (selectedTabValue === 1) {
        return statusMapper[popupHeader]?.split('/')[1];
      }
      return statusMapper[popupHeader] !== undefined &&
        statusMapper[popupHeader].split('/')[1] === 'actions'
        ? statusMapper[popupHeader]?.split('/')[NUMBER_ARRAY.two]
        : statusMapper[popupHeader]?.split('/')[1];
    }
  };
  /**
   *
   *actionsTypeValue is a function which determines the actiontype parameter in Payload
   */
  const actionsTypeValue = (condition) => {
    if (condition) {
      return ' ';
    } else {
      let statusMappervalue;
      if (selectedTabValue === 1) {
        statusMappervalue = statusMapper[popupHeader]?.split('/')[0];
      } else if (
        statusMapper[popupHeader] !== undefined &&
        statusMapper[popupHeader].split('/')[1] === 'actions'
      ) {
        statusMappervalue =
          statusMapper[popupHeader]?.split('/')[0] + '/' + statusMapper[popupHeader]?.split('/')[1];
      } else {
        statusMappervalue = 'trainings';
      }
      return statusMappervalue;
    }
  };
  useEffect(() => {
    if (
      popUserId !== undefined &&
      popUserId !== '' &&
      (filterText !== undefined || teamMemberSearch !== undefined)
    ) {
      const keyword = {
        training: filterText,
        user: teamMemberSearch,
        trainingStatus: trainingStatus,
      };
      let filterPayload = {
        userId: popUserId,
        keyWord: searchTypeRef?.current === undefined ? ' ' : keyword[searchTypeRef.current],
        searchType: searchTypeRef.current,
        getDataOf: getDataOfValue(
          popupHeader === undefined || statusMapper[popupHeader] === undefined,
        ),
        days: selectedDays,
        popUpType: popupStatus,
        loggedInUserId: logInUserDetails.user_id,
        dateRange: dateRangeFilter,
        actionsType: actionsTypeValue(
          popupHeader === undefined || statusMapper[popupHeader] === undefined,
        ),
      };
      let adminFilterPayload = {
        userId: popUserId,
        keyWord: keyword[searchTypeRef.current],
        searchType: searchTypeRef.current,
        getDataOf: statusMapper[popupHeader].split('/')[NUMBER_ARRAY.two],
        days: selectedDays,
        popUpType: popupStatus,
        loggedInUserId: logInUserDetails.user_id,
        dateRange: dateRangeFilter,
        actionsType: statusMapper[popupHeader].split('/')[NUMBER_ARRAY.one],
        country: countryValues?.length ? countryValues : '',
        mrc: mrcNameValues?.length ? mrcNameValues : '',
        legalEntityName: legalEntityValues?.length ? legalEntityValues : '',
        partnerCompany: partnerCompanyValues?.length ? partnerCompanyValues : '',
        userField: userValues?.length ? userValues : '',
        managerField: managerValues?.length ? managerValues : '',
      };
      let adminActionFilterPayload = {
        keyWord: keyword[searchTypeRef.current],
        getDataOf: statusMapper[popupHeader].split('/')[NUMBER_ARRAY.two],
        actionsType: statusMapper[popupHeader].split('/')[NUMBER_ARRAY.one],
        country: countryValues?.length ? countryValues : '',
        mrc: mrcNameValues?.length ? mrcNameValues : '',
        legalEntityName: legalEntityValues?.length ? legalEntityValues : '',
        partnerCompany: partnerCompanyValues?.length ? partnerCompanyValues : '',
        userField: userValues?.length ? userValues : '',
        managerField: managerValues?.length ? managerValues : '',
      };
      if (searchTypeRef.current === 'training') {
        filterPayload['userData'] = teamMemberSearch;
        filterPayload['trainingData'] = '';
        filterPayload['trainingStatus'] = trainingStatus;
      } else if (searchTypeRef.current === 'user') {
        filterPayload['userData'] = '';
        filterPayload['trainingData'] = filterText;
        filterPayload['trainingStatus'] = trainingStatus;
      } else if (searchTypeRef.current === 'trainingStatus') {
        filterPayload['userData'] = teamMemberSearch;
        filterPayload['trainingData'] = filterText;
        filterPayload['trainingStatus'] = '';
      } else {
        //Invalid request
      }

      if (isAdmin) {
        dispatch(
          PopupAdminFilterData(
            statusMapper[popupHeader].split('/')[1] === 'actions'
              ? adminActionFilterPayload
              : adminFilterPayload,
          ),
        );
      } else {
        if (selectedTabValue === 1) {
          dispatch(PopupUpFilterData(filterPayload));
        } else {
          statusMapper[popupHeader] !== undefined &&
          statusMapper[popupHeader].split('/')[1] === 'actions'
            ? dispatch(DirectReporteePopupFilterData(filterPayload))
            : dispatch(DirectReporteeTrainingsPopupFilterData(filterPayload));
        }
      }
    }
  }, [filterText, dateRangeParent, teamMemberSearch, trainingStatus]);

  // Date range Picker
  const info = [
    {
      body: 'Job Description',
      placeholder: 'JD',
    },
    {
      body: 'Summary Of Experience',
      placeholder: 'SOE',
    },
    {
      body: 'Individual Training Plan',
      placeholder: 'ITP',
    },
    {
      body: 'See Training Actions > Trainings Overdue for actionable past due trainings.',
      placeholder: 'Training',
    },
  ];
  const getStartDate = (parentDate, date) => {
    return parentDate < date ? date : parentDate;
  };
  const getEndDate = (parentDate, date) => {
    return parentDate > date ? date : parentDate;
  };
  const onChangeOfDateRange = (date) => {
    if (date !== undefined && date.length > 0) {
      if (date[0] !== undefined && date[0] !== '') {
        if (dateRangeParent?.length) {
          setSelectedDateRange([
            getStartDate(dateRangeParent[0], date[0]),
            getEndDate(dateRangeParent[1], date[1]),
          ]);
        } else {
          setSelectedDateRange([date[0], date[1]]);
        }
      } else {
        setDateRangeFilter([]);
      }
    } else {
      setDateRangeFilter([]);
    }
    setPageState((old) => ({
      ...old,
      page: 1,
    }));
  };

  const dateRangeParentFormatted = dateRangeParent
    ? dateRangeParent.map((date) => dayjs(date))
    : [];

  useEffect(() => {
    if (dashboardFilter?.popupFilterData?.data?.length) {
      const nameWithWWID = dashboardFilter?.popupFilterData?.data.map(
        (na) => `(${na.user_id}) ${na.full_name} `,
      );
      const title = dashboardFilter?.popupFilterData?.data.map((opt) => opt.training_title);
      const status = dashboardFilter?.popupFilterData?.data.map((opt) => opt.is_active);
      let configs = autocompletes;
      configs = configs.map((config) => {
        if (config.label == 'Team Member' || config.label == 'WWID / Team Member') {
          config.options = nameWithWWID;
        }
        if (config.label == 'Training Name') {
          config.options = title;
        }
        if (config.label == 'Training Status') {
          config.options = status;
        }
        return config;
      });
      setAutocompleteConfigs && setAutocompleteConfigs(configs);
    }
  }, [dashboardFilter]);

  const customNoDataOverlay = () => {
    let overlayMssg =
      selectedDateRange.length === 0
        ? 'No Trainings assigned to you at the moment'
        : 'No Trainings found for the filters applied';

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px',
          background: redtheme.palette.box.very_light_grey,
          fontWeight: '900',
          fontSize: '26px',
          color: redtheme.palette.box.dark_grayish_blue,
          width: '100%',
          height: '100%',
        }}
      >
        {overlayMssg}
      </Box>
    );
  };
  const totalRowCount = () => {
    if (unselectedRows?.length) {
      return totalSelectedRecords - unselectedRows?.length;
    } else {
      return totalSelectedRecords;
    }
  };

  const handleColumnHeaderClick = (params, event) => {

    if (params && event.target.checked) {
      setSelectAllChecked(true);
      selectAllCheckboxValue = true;
    } else {
      setSelectAllChecked(false);
      selectAllCheckboxValue = false;
      setSelectedUsersForDownloadExcel([]);
      setDeSelectedUsersForDownloadExcel([]);
      unselectedRows = [];
    }
  };

  const handleOnRowSelection = (newRowSelectionModel) => {

    setSelectionModel(newRowSelectionModel);

    let selectedRowsUpdate = selectedRows.filter((item) =>
      newRowSelectionModel.includes(item.user_id),
    );
    let arrSelectedItems = [];

    if (newRowSelectionModel?.length && !selectAllCheckboxValue) {
      if (isCompliantPopup) {
        newRowSelectionModel.forEach((rowId) => {
          let existingItem = selectedRows.find((row) => row.user_id === rowId);
          if (existingItem === undefined) {
            let rowItem = pageState.data.find((row) => row.user_id === rowId);
            if (rowItem) {
              let complianceElementArray = rowItem.complianceElement.split(', ');
              let complianceArray = '';
              if (complianceElementArray.includes('SOE')) {
                complianceArray += 'Summary of Experience (SOE), ';
              }
              if (complianceElementArray.includes('ITP')) {
                complianceArray += 'Individual Training Plan (ITP), ';
              }
              if (complianceElementArray.includes('JD')) {
                complianceArray += 'Job Description (JD)';
              }
              arrSelectedItems.push({
                complianceElement: complianceArray,
                complianceStatus: rowItem.complianceStatus,
                full_name: rowItem.full_name,
                inspectionReadiness_Indicator: rowItem.inspectionReadiness_Indicator,
                leave_of_absence: rowItem.leave_of_absence,
                user_id: rowItem.user_id,
                user_email: rowItem.user_email,
              });
              //  pushing the selected checkbox elements
              arrcheckbox.push({ user_id: rowItem.user_id, full_name: rowItem.full_name });
            }
          }
        });
        //  filtering the array to remove duplicates user_id
        if (arrcheckbox?.length) {
          uniqueArray = arrcheckbox.filter((o1) =>
            newRowSelectionModel.some((o2) => o1.user_id === o2),
          );
        }
        // filtering the array to remove unselected checkbox value
        if (uniqueArray?.length) {
          arrayValue = uniqueArray.filter(
            (obj, index, self) => index === self.findIndex((o) => o.user_id === obj.user_id),
          );
        }
        // sending the selected checkbox values to excel
        if (arrayValue?.length) {
          setSelectedRows([...selectedRowsUpdate, ...arrSelectedItems]);
          setSelectedUsersForDownloadExcel(arrayValue);
        }
      } else {
        newRowSelectionModel.forEach((rowId) => {
          let existingItem = selectedRows.find((row) => row.transcript_id === rowId);
          if (existingItem === undefined) {
            pageState.data.forEach((element) => {
              if (element.transcript_id === rowId) {
                //  pushing the selected checkbox elements
                arrcheckbox.push(element);
              }
            });
          }
        });

        //  filtering the array to remove duplicates transcript_id
        if (arrcheckbox?.length) {
          uniqueArray = arrcheckbox.filter((o1) =>
            newRowSelectionModel.some((o2) => o1.transcript_id === o2),
          );
        }
        // filtering the array to remove unselected checkbox value
        if (uniqueArray?.length) {
          arrayValue = uniqueArray.filter(
            (obj, index, self) =>
              index === self.findIndex((o) => o.transcript_id === obj.transcript_id),
          );
        }

        if (arrayValue?.length) {
          checkboxArray = arrayValue.map((element) => {
            arrSelectedItems.push(element);
            // returning only userid, full name and training id
            return {
              user_id: element.user_id,
              full_name: element.full_name,
              training_id: element.training_id,
            };
          });
        }
        // sending array to download excel
        if (checkboxArray?.length) {
          setSelectedRows([...selectedRowsUpdate, ...arrSelectedItems]);
          setSelectedUsersForDownloadExcel(checkboxArray);
        }
      }
    } else if (selectAllCheckboxValue) {
      if (newRowSelectionModel?.length <= pageState.pageSize) {
        if (isCompliantPopup) {
          const deSelectedRows = pageState.data.filter(
            (item) => !newRowSelectionModel.includes(item.user_id),
          );

          const currentPageIds = pageState.data.map((item) => item.user_id);
          unselectedRows = [
            ...unselectedRows.filter((item) => !currentPageIds.includes(item.user_id)),
            ...deSelectedRows,
          ];
         
          if (unselectedRows?.length) {
            setDeSelectedUsersForDownloadExcel(
              unselectedRows.map((element) => {
                if (element) {
                  let complianceElementArray = element.complianceElement.split(', ');
                  let complianceArray = '';
                  if (complianceElementArray.includes('SOE')) {
                    complianceArray += 'Summary of Experience (SOE), ';
                  }
                  if (complianceElementArray.includes('ITP')) {
                    complianceArray += 'Individual Training Plan (ITP), ';
                  }
                  if (complianceElementArray.includes('JD')) {
                    complianceArray += 'Job Description (JD)';
                  }
                  arrSelectedItems.push({
                    complianceElement: complianceArray,
                    complianceStatus: element.complianceStatus,
                    full_name: element.full_name,
                    inspectionReadiness_Indicator: element.inspectionReadiness_Indicator,
                    leave_of_absence: element.leave_of_absence,
                    user_id: element.user_id,
                    user_email: element.user_email,
                  });

                  return {
                    user_id: element.user_id,
                    full_name: element.full_name,
                  };
                }
              }),
            );
            setSelectedRows([...selectedRowsUpdate, ...arrSelectedItems]);
          }
        } else {
          const deSelectedRows = pageState.data.filter(
            (item) => !newRowSelectionModel.includes(item.transcript_id),
          );
          const currentPageIds = pageState.data.map((item) => item.transcript_id);
          unselectedRows = [
            ...unselectedRows.filter((item) => !currentPageIds.includes(item.transcript_id)),
            ...deSelectedRows,
          ];
          if (unselectedRows?.length) {
           
            setDeSelectedUsersForDownloadExcel(
              unselectedRows.map((element) => {
                arrSelectedItems.push(element);
                return {
                  user_id: element.user_id,
                  full_name: element.full_name,
                  training_id: element.training_id,
                };
              }),
            );
            setSelectedRows([...selectedRowsUpdate, ...arrSelectedItems]);
          }
        }
      } else {
        setSelectedUsersForDownloadExcel([]);
      }
    } else {
      setSelectedRows([]);
      setSelectedUsersForDownloadExcel([]);
    }
  };

  const DownloadExcelButton = {
    // Styles for "Download Excel" button
    backgroundColor: DOWNLOAD_EXCEL_BUTTON_BG_COLOR,
    color: DOWNLOAD_EXCEL_BUTTON_COLOR,
    padding: '9px 30px',
    borderRadius: '2px',
    gap: '10px',
    textTransform: 'none',
    fontWeight: DOWNLOAD_EXCEL_BUTTON_FONTWEIGHT,
    fontSize: screen.isMobile? '14px':'16px',
    width: screen.isMobile? '160px':'200px',
    height: '38px',
    fontFamily: '"JohnsonText-Medium"',
    '&:hover': {
      backgroundColor: redtheme.palette.button.strong_red,
      color: redtheme.palette.button.white,
    },
    '&:focus': { outline: 'none' },
  };

  const SendReminderButton = {
    color: CheckSendReminder() && !selectAllCheckboxValue ? redtheme.palette.button.gray : redtheme.palette.button.dark_grey,
    border: CheckSendReminder() && !selectAllCheckboxValue ? '' : `1px solid ${redtheme.palette.primary.main}`,
    backgroundColor: CheckSendReminder() && !selectAllCheckboxValue ? redtheme.palette.button.light_gray : 'transparent',
    textTransform: 'none',
    // fontWeight: CheckSendReminder() ? fontWeightStyles.fw9.fontWeight : fontWeightStyles.fw7.fontWeight,
    // fontSize: screen.isMobile? '14px':'16px',
    // width: screen.isMobile? '150px':'200px',
    fontWeight: CheckSendReminder() && !selectAllCheckboxValue
      ? fontWeightStyles.fw9.fontWeight
      : fontWeightStyles.fw7.fontWeight,
    fontSize: screen.isMobile? '14px':'16px',
    width: screen.isMobile? '150px':'200px',
    height: '38px',
    fontFamily: '"JohnsonText-Medium"',
    '&:hover': { backgroundColor: 'transparent' },
    '&:focus': { outline: 'none' },
  };

  const HandelButtonClick = (button) => {
    if (button.label === 'Send Reminder') {
      handleSendReminder();
    }
    if (button.label === 'Download Excel') {
      handleDownloadExcel();
    }
  };

  const autocompleateSection = () => {
    return (
      autocompletes &&
      autocompletes.map((autocomplete, index) => (
        <Grid key = {index} item xs = {screen.isMobile ? (autocompletes.length === 2 ? 6 : 12) : (isPopupOpenedForCompliance() ? (autocompletes.length === 2 ? 6 : 12) : (autocompletes.length === 2 ? 4 : 6) )}>
        <Autocomplete
          id='search-training-by-label'
          data-testid='autocomplete-search'
          key={index}
          options={autocomplete.options}
          getOptionLabel={autocomplete.getOptionLabel}
          onInputChange={(e, value) => handleSearchText(e, value, autocomplete.label)}
          onChange={(_event, newValue) => {
            const searchType = autocomplete.label === 'Training Name' ? 'training' : 'user';
            if (searchType === 'training') {
              setTrainingTitle(newValue);
            } else {
              setSelectedOption(newValue);
            }
            setPageState((old) => ({ ...old, searchType: searchType, page: 1 }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                shrink: true,
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              label={
                <Typography
                  sx={{
                    fontSize: '16px',
                    lineHeight: '14px',
                    fontWeight: 500,
                    fontFamily: '"JohnsonText-Bold"',
                    color: 'rgba(33, 33, 33, 1)',
                    width: '728px',
                    height: '16px',
                  }}
                >
                  {autocomplete.label}
                </Typography>
              }
              variant='standard'
              margin='normal'
              placeholder="Search"
              sx={{ 
                // width: isPopupOpenedForCompliance() ? screen.resolveStyles({
                //   mobile : '120px',
                //   tablet : '520px', 
                //   desktop : '820px',
                // }) : autocomplete.minWidth,
                mr: autocomplete.mr,
                borderBottom: '1px solid black',
                // Merge additional custom styles
                ...autocomplete.sx,
              }}
            />
          )}
          clearOnBlur
        />
        </Grid>
      ))
    );
  };

  const trainingTable = () => {
    return noTrainingsAssigned ? (
      <DataGrid
        loading={pageState.isLoading}
        rows={pageState.data}
        columns={columns}
        slots={{
          noRowsOverlay: customNoDataOverlay,
        }}
        sx={{
          border: 'none',
          minHeight: '480px',
          '& .MuiDataGrid-columnHeaders': {
            fontSize: 16,
            fontFamily: '"J&J CircularTT Black Web"',
          },
        }}
      />
    ) : (
      <DataGrid
        rows={pageState.data}
        columns={columns}
        getRowId={(row) => (isCompliantPopup ? row?.user_id : row?.transcript_id)}
        loading={pageState.isLoading}
        checkboxSelection={isCheckboxSelection}
        sortingMode='server'
        paginationMode='server'
        localeText={{
          columnHeaderSortIconLabel: sortingTooltip,
          footerRowSelected: (count) =>

          {  
          
            return selectAllCheckboxValue
              ? `${totalRowCount()} rows selected`
              : `${count.toLocaleString()} rows selected`
            }
        }}
        onSortModelChange={handleSortModelChange}
        setPageState={setPageState}
        rowCount={pageState.total}
        disableRowSelectionOnClick
        onRowSelectionModelChange={(newRowSelectionModel) => {
          handleOnRowSelection(newRowSelectionModel);
        }}
        onColumnHeaderClick={handleColumnHeaderClick}
        keepNonExistentRowsSelected
        rowSelectionModel={selectionModel}
        slots={{
          noRowsOverlay: customNoDataOverlay,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={PAGE_SIZES_ARRAY_GENERIC}
        onPaginationModelChange={(newPage) => {
          setPageState((old) => ({
            ...old,
            page: newPage.page + 1,
            pageSize: newPage.pageSize,
          }));
        }}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        sx={{
          border: 'none',
          '& .MuiDataGrid-cell': {
            fontSize: '14px',
            fontWeight: 450,
            fontFamily: '"J&J CircularTT Book Web"',
          },
          '& .MuiDataGrid-row:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 153, 0.03)',
          },
          '& .MuiDataGrid-row:nth-of-type(odd):hover': {
            backgroundColor: redtheme.palette.dataGrid.very_light_grey,
          },
          '& .MuiDataGrid-columnHeaders': {
            fontSize: 16,
            fontFamily: '"J&J CircularTT Black Web"',
          },
          '& .MuiDataGrid-row.Mui-selected': { backgroundColor: redtheme.palette.dataGrid.white },
          '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: redtheme.palette.dataGrid.white,
          },
          '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 153, 0.03)',
          },
          '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd):hover': {
            backgroundColor: redtheme.palette.dataGrid.very_light_grey,
          },
          '& .MuiDataGrid-rowCount': {
            fontWeight: 'bold',
          },
          '& .MuiTablePagination-selectLabel': {
            fontFamily: '"J&J CircularTT Black Web"',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '18px',
            fontStyle: 'normal',
            color: redtheme.palette.dataGrid.fontGrey,
          },
          '& .MuiTablePagination-displayedRows': {
            fontFamily: '"J&J CircularTT Black Web"',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '18px',
            fontStyle: 'normal',
            color: redtheme.palette.dataGrid.fontGrey,
          },
          '& .MuiTablePagination-select': {
            fontFamily: '"J&J CircularTT Black Web"',
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '26px',
            fontStyle: 'normal',
            color: redtheme.palette.dataGrid.fontGrey,
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cellContent': {
            pointerEvents: 'none',
            fontWeight: '400',
            fontSize: '1rem',
          },
              [`& .${tablePaginationClasses.selectLabel}`]: {
                display: "block"
              },
              [`& .${tablePaginationClasses.input}`]: {
                display: "inline-flex"
              }
        }}
      />
    );
  };

  const buttonDisplay = () => {
    return (
      buttons && (
        <Stack direction='row' spacing={2} mt={2}>
          {buttons.map((button, index) => (
            <Button
              data-testid={button.label === 'Send Reminder' ? 'send-reminder' : 'download-excel'}
              key={index}
              onClick={() => {
                HandelButtonClick(button);
              }}
              sx={{
                ...(button.label === 'Send Reminder' ? SendReminderButton : {}),
                ...(button.label === 'Download Excel' ? DownloadExcelButton : {}),
                borderRadius: '12px',
              }}
              disabled={
                buttonDisabled &&
                ((button.label === 'Send Reminder' && selectionModel?.length <= 0 && !unselectedRows?.length) ||
                  (button.label === 'Send Reminder' && CheckSendReminder() && !selectAllCheckboxValue ) ||
                  (pageState.data?.length <= 0 && button.label === 'Download Excel'))
              }
            >
              {button.label} {button.label === 'Download Excel' ? <Download/>: '' } 
            </Button>
          ))}
        </Stack>
      )
    );
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor:  redtheme.palette.box.white,
            padding: screen.isMobile ? '16px':'10px 46px 10px',
            gap: '48px',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            width: screen.resolveStyles({
              mobile: '360px',
              tablet: '618px', 
              desktop: '918px',
              }),
            borderRadius: '16px',
          }}
        >
          <IconButton
            data-testid='btn-close'
            className='btn-close-popup'
            sx={{
              position: 'absolute',
              top: '30px',
              right: '30px',
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant='h2'
            component='div'
            sx={{ weight: fontWeightStyles.fw9.fontWeight, fontFamily: '"JohnsonDisplay-Bold"' }}
          >
            {popupHeader}
          </Typography>
          <Grid container spacing={2}>
            {autocompleateSection()}
            <Grid item xs={screen.isMobile ? 12 : autocompletes.length === 2 ? 4 : 6}>
            {!isPopupOpenedForCompliance() && (
              <Box>
                <InputLabel
                  data-testid='date-search'
                  sx={{
                    position: 'absolute',
                    paddingTop: '15px',
                    fontSize: '12px',
                    lineHeight: '12px',
                    fontWeight: 500,
                    fontFamily: '"JohnsonText-Bold"',
                    color: 'rgba(33, 33, 33, 1)',
                  }}
                >
                  Start Date to End Date
                </InputLabel>
                <DateRangePicker
                  onChange={onChangeOfDateRange}
                  defaultValue={dateRangeParentFormatted}
                  width={screen.isMobile ? '330px':'0px'}
                />
              </Box>
            )}
            </Grid>
            </Grid>
          <Box sx={{ height: 270, width: '100%' }}>{trainingTable()}</Box>
          <Box
            sx={{
              marginLeft: '15px',
              width: '300px',
              paddingTop:screen.isMobile?'20px':null,
            }}
          >
            {isCompliantPopup ? (
              <Stack direction='row' spacing={2}>
                <PopupInfo />
                {info.map((infos, index) => (
                  <GenericPopupTooltip
                    key={index}
                    title={infos.body}
                    placement='top'
                    header={infos.placeholder}
                  />
                ))}
              </Stack>
            ) : null}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '-11px',
            }}
          >
            {buttonDisplay()}
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity='success'
          elevation={6}
          variant='filled'
          style={{ color: 'white', fontFamily: '"J&J CircularTT Bold Web"' }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default memo(GenericPopup);

GenericPopup.propTypes = {
  open: PropTypes.boolean,
  onClose: PropTypes.func,
  selectedDays: PropTypes.integer,
  columns: PropTypes.array,
  buttons: PropTypes.array,
  autocompletes: PropTypes.array,
  statusMapper: PropTypes.Object,
  popupHeader: PropTypes.string,
  popUserId: PropTypes.string,
  popupStatus: PropTypes.string,
  dateRangeParent: PropTypes.array,
  setAutocompleteConfigs: PropTypes.func,
  isCheckboxSelection: PropTypes.boolean,
  buttonDisabled: PropTypes.boolean,
};
